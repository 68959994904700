import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as Ant from 'antd';
import * as AppActions from '../AppActions';

const L = AppActions.L;

export default function CustomerSupportForm(props) {
  useOutlet('locale');
  const [values, _setValues] = useState({});
  const [customer_support_config] = useOutlet('customer_support_config');
  const options = customer_support_config?.options || [];
  const [selectedOption, setSelectedOption] = useState();

  const setValues = useCallback(
    (obj) => _setValues((prev) => ({...prev, ...obj})),
    [],
  );

  const submit = useCallback(async (values) => {
    AppActions.setLoading(true);
    try {
      await AppActions.sendCustomerSupport(values);
      Ant.message.success('送出成功');
    } catch (err) {
      console.log(err);
      Ant.message.warn('送出失敗');
    }
    AppActions.setLoading(false);
  }, []);

  return (
    <Form className="form">
      <div className="label">{L('name')}</div>
      <Ant.Input
        value={values.name}
        onChange={(e) => setValues({name: e.target.value})}
      />

      <div className="label">{L('phone')}</div>
      <Ant.Input
        value={values.phone}
        onChange={(e) => setValues({phone: e.target.value})}
      />

      <div className="label">{L('email')}</div>
      <Ant.Input
        value={values.email}
        onChange={(e) => setValues({email: e.target.value})}
      />

      <div className="label">{L('customer_support_title')}</div>
      <Ant.Select
        value={values.title}
        onChange={(value, option) => {
          console.log('selected option', option);
          setValues({title: value});
          setSelectedOption(option);
        }}
        style={{width: '100%'}}>
        {options.map((option, idx) => (
          <Ant.Select.Option key={idx} value={option.value} hint={option.hint}>
            {option.value}
          </Ant.Select.Option>
        ))}
      </Ant.Select>

      {selectedOption && <div className="hint">{selectedOption.hint}</div>}

      <div className="label">{L('customer_support_content')}</div>
      <Ant.Input.TextArea
        rows={6}
        value={values.content}
        onChange={(e) => setValues({content: e.target.value})}
      />

      <Ant.Button
        type="primary"
        size="large"
        block
        onClick={() => submit(values)}
        style={{marginTop: 16}}>
        {L('confirm')}
      </Ant.Button>
    </Form>
  );
}

const Form = styled.div`
  width: 400px;
  margin: 0 auto 20px auto;
  padding: 20px;
  text-align: left;
  background-color: #fff;

  & .label {
    margin-top: 20px;
    margin-bottom: 8px;
  }

  & .hint {
    margin-top: 20px;
    &::before {
      content: '*';
      margin-right: 3px;
    }
  }

  @media screen and (max-width: 768px) {
    & > .content > .form {
    }
  }
`;
